import './App.css';
import YoutubeComponent from './YoutubeComponent'
import { ThemeProvider, CssBaseline, useMediaQuery, createMuiTheme, Grid } from '@material-ui/core';
import React from 'react';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          "primary": {
            "light": "rgba(94, 184, 255, 1)",
            "main": "rgba(74, 144, 226, 1)",
            "dark": "rgba(0, 91, 159, 1)",
            "contrastText": "#fff"
          },
          "secondary": {
            "light": "#ff4081",
            "main": "#f50057",
            "dark": "#c51162",
            "contrastText": "#fff"
          },
          type: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Grid
          container
          spacing={0}
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item md={6} xs={10}>
            <YoutubeComponent></YoutubeComponent>
          </Grid>
        </Grid>;
      </div>
    </ThemeProvider>
  );
}

export default App;
