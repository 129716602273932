import { Component } from "react";
import { Card, CardMedia, CardContent, Typography } from "@material-ui/core";

export default class MetaInformationCardComponent extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (<Card>
      <CardMedia
        component="img"
        alt={this.props.title}
        height="140"
        image={this.props.imageUrl}
        title={this.props.title}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {this.props.title}
        </Typography>
      </CardContent>
    </Card >);
  }
}