import React, { Component } from 'react';
import { TextField, Button, LinearProgress, InputAdornment, Grid } from '@material-ui/core'
import { saveAs } from 'file-saver';
import SearchIcon from '@material-ui/icons/Search'
import { HubConnectionBuilder } from '@microsoft/signalr';
import MetaInformationCardComponent from './MetaInformationCardComponent';

export default class YoutubeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoading: false,
            url: "",
            progress: 0,
            metaInfo: undefined,
        };

        this.downloadVideo = this.downloadVideo.bind(this);

        const newConnection = new HubConnectionBuilder()
            .withUrl(this.baseUrl + '/hubs/progress')
            .withAutomaticReconnect()
            .build();

        newConnection.start()
            .then(result => {
                this.connectionId = newConnection.connectionId;

                newConnection.on('ReceiveProgress', progress => {
                    this.setState({ progress: progress * 100 });
                });

                newConnection.on('ReceiveMetaInfo', metaInfo => {
                    this.setState({ metaInfo: metaInfo });
                });
            }).catch(e => console.log('connection failed: ', e));
    }

    baseUrl = "https://ytdl-api.ds007.myds.me";
    // baseUrl = "https://localhost:49153";

    connectionId = undefined;

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.downloadVideo();
            e.preventDefault();
        }
    }

    handleChange = (e) => {
        this.setState({ url: e.target.value });
    };

    componentDidMount() {
    }

    render() {
        let styles = {
            marginTop: '5px',
        };

        const { isLoading, progress, metaInfo } = this.state;
        return (
            <Grid
                container
                direction="column"
                spacing={1}
            >
                <Grid item>
                    <form noValidate autoComplete="off">
                        <TextField disabled={isLoading} autoFocus onKeyDown={this._handleKeyDown} type="search" margin="normal" fullWidth id="standard-basic" value={this.state.url}
                            onChange={this.handleChange} label="Insert YouTube Link" InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }} />
                        <Button margin="normal" fullWidth disabled={isLoading} onClick={this.downloadVideo} variant="contained" color="primary">
                            Download
                        </Button>
                        {isLoading && <LinearProgress style={styles} margin={5} variant="determinate" value={progress} />}
                    </form >
                </Grid>
                <Grid item>
                    {metaInfo && <MetaInformationCardComponent title={metaInfo.title} imageUrl={metaInfo.imageUrl}></MetaInformationCardComponent>}
                </Grid>
            </Grid >
        );
    }

    async downloadVideo() {
        const { url } = this.state;

        if (!url || url.length === 0) {
            return;
        }

        this.setState({
            isLoading: true,
            progress: 0,
            metaInfo: undefined,
        });


        try {
            var response = await fetch(this.baseUrl + "/get?l=" + url + '&c=' + this.connectionId);

            if (!response.ok) {
                throw new Error(response);
            }

            const filename = response.headers.get('content-disposition')
                .split(';')
                .find(n => n.includes('filename='))
                .replace('filename=', '')
                .replace('"', '')
                .replace('"', '')
                .trim();

            const blob = await response.blob();

            // Download the file
            saveAs(blob, filename);

            this.setState({ isLoading: false });

        } catch (error) {
            this.setState({ isLoading: false });
            throw new Error(error);
        }

    }
}